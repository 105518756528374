import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Section, Text, Gap, Col, Link, Button } from '../components'

class Page404 extends Component {
  render() {
    return (
      <Section>
        <Gap.Top />
        <Gap gap="200px" />
        <Col justifyContent="center" alignItems="center">
          <Text>404 - Page not found</Text>
          <Gap gap="20px" />
          <Button.Link to="/">Return to homepage</Button.Link>
        </Col>
        <Gap gap="200px" />
      </Section>
    )
  }
}

export default Page404
